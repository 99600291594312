import { addPropertyControls, ControlType } from "framer"
import type { Graph } from "schema-dts"

/**
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function AuthorSchema(props) {
    const graph: Graph = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": "ProfilePage",
                headline: props.headline,
                url: `${props.websiteUrl}/author/${props.profileUrl}`,
                "@id": `${props.websiteUrl}/author/${props.profileUrl}#profilepage`,
                inLanguage: props.language,
                image: props.image ? props.image.src : undefined,
                description: props.description ? props.description : undefined,
                mainEntity: {
                    "@id": `${props.websiteUrl}/author/${props.profileUrl}#person`,
                },
                isPartOf: {
                    "@type": "WebSite",
                    name: props.websiteName,
                    url: props.websiteUrl,
                    "@id": `${props.websiteUrl}#website`,
                    publisher: {
                        "@type": "Organization",
                        name: props.organizationName,
                        url: props.organizationUrl,
                        "@id": `${props.organizationUrl}#organization`,
                    },
                },
            },
            {
                "@type": "Person",
                name: props.name,
                url: `${props.websiteUrl}/author/${props.profileUrl}`,
                "@id": `${props.websiteUrl}/author/${props.profileUrl}#person`,
                worksFor: { "@id": `${props.organizationUrl}#organization` },
                image: props.image ? props.image.src : undefined,
                description: props.description ? props.description : undefined,
                alumniOf: props.alumniOf
                    ? {
                          "@type": "Organization",
                          name: props.alumniOf,
                          "@id": props.alumniOfId,
                      }
                    : undefined,
                sameAs: props.linkedin ? [props.linkedin] : undefined,
                jobTitle: {
                    "@type": "DefinedTerm",
                    name: props.jobTitle,
                    alternateName: props.jobTitleAlternateName,
                    sameAs: props.jobTitleUrl,
                },
                knowsLanguage: props.knowsLanguage
                    ?.split(",")
                    .map((lang) => lang.trim()),
                knowsAbout: props.knowsAbout?.map((topic) => ({
                    "@type": "Thing",
                    name: topic.name,
                    alternateName: topic.alternateName,
                    "@id": topic.id,
                })),
            },
        ],
    }

    return (
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(graph) }}
        />
    )
}

addPropertyControls(AuthorSchema, {
    headline: {
        title: "Headline",
        type: ControlType.String,
        defaultValue: "Author Name | Organization",
    },
    description: {
        title: "Description",
        type: ControlType.String,
    },
    profileUrl: {
        title: "Profile URL",
        type: ControlType.String,
        defaultValue: "https://example.com/author/author-name",
    },
    image: {
        title: "Image",
        type: ControlType.ResponsiveImage,
    },
    language: {
        title: "Language",
        type: ControlType.String,
        defaultValue: "en-US",
    },
    name: {
        title: "Name",
        type: ControlType.String,
        defaultValue: "Author Name",
    },
    alumniOf: {
        title: "Alumni Of",
        type: ControlType.String,
        defaultValue: "Western Sydney University",
    },
    alumniOfId: {
        title: "Alumni Of ID",
        type: ControlType.String,
        defaultValue: "https://www.wikidata.org/wiki/Q1141452",
    },
    jobTitle: {
        title: "Job Title",
        type: ControlType.String,
        defaultValue: "Registered Psychologist",
    },
    jobTitleAlternateName: {
        title: "Job Title Alternate",
        type: ControlType.String,
        defaultValue: "Psychologist",
    },
    jobTitleUrl: {
        title: "Job Title URL",
        type: ControlType.String,
        defaultValue:
            "https://resources.workable.com/psychologist-job-description",
    },
    knowsLanguage: {
        title: "Languages",
        type: ControlType.String,
        defaultValue: "English, Greek",
    },
    knowsAbout: {
        title: "Knows About",
        type: ControlType.Array,
        propertyControl: {
            type: ControlType.Object,
            controls: {
                name: { title: "Name", type: ControlType.String },
                alternateName: {
                    title: "Alternate Name",
                    type: ControlType.String,
                },
                id: { title: "ID", type: ControlType.String },
            },
        },
        defaultValue: [
            {
                name: "Cognitive Behavioral Therapy",
                alternateName: "CBT",
                id: "https://www.wikidata.org/wiki/Q1147152",
            },
        ],
    },
    websiteName: {
        title: "Website Name",
        type: ControlType.String,
        defaultValue: "Example Website",
    },
    websiteUrl: {
        title: "Website URL",
        type: ControlType.String,
        defaultValue: "https://example.com",
    },
    organizationName: {
        title: "Organization Name",
        type: ControlType.String,
        defaultValue: "Example Organization",
    },
    organizationUrl: {
        title: "Organization URL",
        type: ControlType.String,
        defaultValue: "https://example.com/organization",
    },
    linkedin: {
        title: "LinkedIn Profile",
        type: ControlType.Link,
        defaultValue: "https://linkedin.com/in/example",
    },
})
